import React from 'react';

const IconSelect = ({ value, onChange, options }) => {
    const handleChange = (e) => {
        const { value } = e.target;
        onChange({ target: { name: 'icon', value } });
    };

    return (
        <div className="icon-select-container">
            <select value={value} onChange={handleChange} className="icon-select">
                <option value="">-</option>
                {options.map(option => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
            <div className="icon-preview">
                {value ? <ion-icon name={`${value}-outline`}></ion-icon> : <span> </span>}
            </div>
        </div>
    );
};

export default IconSelect;