import React from 'react';
import './styles/SettingsCard.css';
import { requestNotificationsPermission } from "../firebase";
import { useContext } from 'react';
import { LocationContext } from "../context/LocationContext";

const SettingsCard = () => {
    const { setLocation, setLocationDenied } = useContext(LocationContext);

    const handleNotificationsRequest = () => {
        requestNotificationsPermission().then((token) => {
            console.log('User granted notifications permission and FCM token is:', token);
        }).catch((err) => {
            console.error('User denied permission or an error occurred:', err);
        });
    };

    const handleGeolocationRequest = () => {
        if (!navigator.geolocation) {
            const errorMessage = 'La géolocalisation n\'est pas supportée par ce navigateur.';
            console.error(errorMessage);
            setLocationDenied(true);
        } else {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation(position.coords);
                    setLocationDenied(false);
                    console.log('User granted geolocation permission');
                },
                (error) => {
                    let errorMessage;
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            errorMessage = 'L\'utilisateur a refusé la demande de géolocalisation.';
                            break;
                        case error.POSITION_UNAVAILABLE:
                            errorMessage = 'Les informations de localisation ne sont pas disponibles.';
                            break;
                        case error.TIMEOUT:
                            errorMessage = 'La demande de localisation a expiré.';
                            break;
                        default:
                            errorMessage = 'Une erreur inconnue est survenue.';
                            break;
                    }
                    console.error(errorMessage);
                    setLocationDenied(true);
                },
                { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
            );
        }
    };

    const handleContact = () => {
        alert("Contactez-nous à contact@lesmechants.com pour les collaborations et la publicité.");
    };

    return (
        <div className="settings-card">
            <h3>Réglages</h3>
            <div className="settings">
                <button onClick={handleNotificationsRequest}>Activer les notifications</button>
                <button onClick={handleGeolocationRequest}>Activer la géolocalisation</button>
                <button onClick={handleContact}>Contactez-nous</button>
            </div>
        </div>
    );
};

export default SettingsCard;