import React, { useState, useEffect, useContext } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './styles/Map.css';
import { addCommentToMarker, getMarkerById } from '../utils/api';
import { LocationContext } from "../context/LocationContext";
import accidentIconUrl from '../assets/warning-icon.png';
import radarIconUrl from '../assets/radar-icon.png';
import policeIconUrl from '../assets/cops-icon.png';
import selectionIconUrl from '../assets/marker-icon.png';
import useCreateDefaultUser from "../hooks/useCreateDefaultUser";
import { formatDistanceToNow, isValid } from 'date-fns';
import { fr } from 'date-fns/locale';

const markerIcons = {
    accident: new L.Icon({
        iconUrl: accidentIconUrl,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    }),
    radar: new L.Icon({
        iconUrl: radarIconUrl,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    }),
    police: new L.Icon({
        iconUrl: policeIconUrl,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    }),
    currentLocation: new L.Icon({
        iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
        iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    }),
    selection: new L.Icon({
        iconUrl: selectionIconUrl,
        iconRetinaUrl: selectionIconUrl,
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    }),
};

const CenterMap = ({ location, shouldCenter }) => {
    const map = useMap();

    useEffect(() => {
        if (location && shouldCenter) {
            map.setView([location.latitude, location.longitude], 17);
        }
    }, [location, shouldCenter, map]);

    return null;
};

const MapEventsHandler = ({ onMapClick, setLocation, setUserInteracted }) => {
    useMapEvents({
        click: onMapClick,
        dragstart: () => {
            setUserInteracted(true);
        },
        zoomstart: () => {
            setUserInteracted(true);
        },
        locationfound(e) {
            setLocation({ latitude: e.latlng.lat, longitude: e.latlng.lng });
        },
    });
    return null;
};

const Map = ({ markers, onMapClick, currentPosition, mapCenter, mapZoom, shouldCenter }) => {
    const { location, setLocation } = useContext(LocationContext);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [commentText, setCommentText] = useState('');
    const { deviceId, userProfile } = useCreateDefaultUser();
    const [userInteracted, setUserInteracted] = useState(false);

    const userIcon = userProfile?.photoUrl ? new L.Icon({
        iconUrl: userProfile.photoUrl,
        iconSize: [40, 40],
        iconAnchor: [25, 50],
        popupAnchor: [0, -50],
        className: 'user-location-icon'
    }) : markerIcons.currentLocation;

    const handleMarkerClick = async (marker) => {
        try {
            const markerDetails = await getMarkerById(marker._id);
            setSelectedMarker(markerDetails);
            setCommentText(''); // Clear comment text when a new marker is selected
        } catch (error) {
            console.error('Erreur lors de la récupération du marqueur:', error);
        }
    };

    const handleCommentSubmit = async () => {
        if (commentText.trim() === '') return;

        try {
            await addCommentToMarker(selectedMarker._id, commentText, deviceId);
            // Refresh the selected marker's details
            const refreshedMarker = await getMarkerById(selectedMarker._id);
            setSelectedMarker(refreshedMarker);
            setCommentText('');
        } catch (error) {
            console.error('Erreur lors de l\'ajout du commentaire:', error);
        }
    };

    return (
        <MapContainer
            center={mapCenter}
            zoom={mapZoom}
            style={{ height: '100%', width: '100%' }}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <CenterMap location={location} shouldCenter={!userInteracted && shouldCenter} />
            <MapEventsHandler onMapClick={onMapClick} setLocation={setLocation} setUserInteracted={setUserInteracted} />
            {markers.map((marker, idx) => (
                <Marker
                    key={idx}
                    position={[marker.lat, marker.lng]}
                    icon={markerIcons[marker.type]}
                    eventHandlers={{
                        click: () => handleMarkerClick(marker),
                    }}
                >
                    {selectedMarker && selectedMarker._id === marker._id && (
                        <Popup>
                            <div>
                                <h3>{marker.type.charAt(0).toUpperCase() + marker.type.slice(1)}</h3>
                                <span style={{ display: 'block', fontSize: '0.8em', color: 'gray', marginTop: '4px', marginBottom: '8px' }}>
                                    {isValid(new Date(marker.createdAt))
                                        ? formatDistanceToNow(new Date(marker.createdAt), { addSuffix: true, locale: fr })
                                        : 'Date invalide'}
                                </span>
                                <div>
                                    {selectedMarker.comments.length > 0 ? (
                                        selectedMarker.comments.map((comment, index) => (
                                            <div key={index} className="comment">
                                                {comment.photoUrl && <img src={comment.photoUrl} alt="user" className="comment-photo" />}
                                                <div className="comment-text">
                                                    <strong>{comment.username || comment.deviceId}</strong> {comment.text}
                                                    <p className="comment-date">{new Date(comment.createdAt).toLocaleString()}</p>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>Pas de commentaires.</p>
                                    )}
                                </div>
                                <textarea
                                    value={commentText}
                                    onChange={(e) => setCommentText(e.target.value)}
                                    placeholder="Ajouter un commentaire..."
                                    className="comment-input"
                                />
                                <button onClick={handleCommentSubmit} className="menu-button">Valider</button>
                            </div>
                        </Popup>
                    )}
                </Marker>
            ))}
            {location && (
                <Marker position={[location.latitude, location.longitude]} icon={userIcon}>
                    <Popup>
                        Vous êtes ici!
                    </Popup>
                </Marker>
            )}
            {currentPosition && (
                <Marker position={[currentPosition.lat, currentPosition.lng]} icon={markerIcons.selection}>
                    <Popup>
                        Position selectionnée
                    </Popup>
                </Marker>
            )}
        </MapContainer>
    );
};

export default Map;