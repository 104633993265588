import React, { useState, useEffect } from 'react';
import '../components/styles/Profile.css';
import UserCard from '../components/UserCard';
import SettingsCard from '../components/SettingsCard';
import useCreateDefaultUser from '../hooks/useCreateDefaultUser';

const Profile = () => {
    const { deviceId, userProfile, loading, updateUserProfile } = useCreateDefaultUser();
    const [username, setUsername] = useState('');
    const [photoUrl, setPhotoUrl] = useState('pfp/default-profile.jpg');
    const [photoFile, setPhotoFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [editUsername, setEditUsername] = useState(false);
    const [tempUsername, setTempUsername] = useState('');

    useEffect(() => {
        if (userProfile) {
            setUsername(userProfile.username);
            setTempUsername(userProfile.username);
            setPhotoUrl(userProfile.photoUrl);
        }
    }, [userProfile]);

    const handleUsernameChange = (event) => {
        setTempUsername(event.target.value);
    };

    const handlePhotoChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setPhotoFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPhotoUrl(reader.result);
            };
            reader.readAsDataURL(file);

            const profile = {
                username,
                deviceId,
                photoFile: file,
            };

            setUploading(true);
            try {
                await updateUserProfile(profile);
            } catch (error) {
                console.error('Erreur lors de la mise à jour de la photo de profil:', error);
            }
            setUploading(false);
        }
    };

    const handleSaveProfile = async (updatedUsername = username) => {
        const profile = {
            username: updatedUsername,
            deviceId,
            photoFile
        };

        setUploading(true);
        try {
            await updateUserProfile(profile);
        } catch (error) {
            console.error('Erreur lors de la sauvegarde du profil utilisateur:', error);
        }
        setUploading(false);
    };

    const handlePhotoClick = () => {
        document.getElementById('photoInput').click();
    };

    const handleUsernameSave = () => {
        setEditUsername(false);
        handleSaveProfile(tempUsername);
    };

    const handleUsernameCancel = () => {
        setEditUsername(false);
        setTempUsername(username);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="profile">
            <UserCard
                username={tempUsername}
                photoUrl={photoUrl}
                onPhotoClick={handlePhotoClick}
                editUsername={editUsername}
                setEditUsername={setEditUsername}
                handleUsernameChange={handleUsernameChange}
                handleUsernameSave={handleUsernameSave}
                handleUsernameCancel={handleUsernameCancel}
            />
            <SettingsCard />
            <div className="profile-form">
                <input
                    type="file"
                    id="photoInput"
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={handlePhotoChange}
                />
                {uploading && <p>Uploading...</p>}
            </div>
            <footer className="credits">
                Application développée par SlKzᵍᵐ.
                <br/>
                Pour toute question, contactez-moi à <a href="mailto:contact@slkzgm.com">contact@slkzgm.com</a>.
            </footer>
            <footer className="trademark">© 2024 Les Méchants. All rights reserved.</footer>
        </div>
    );
};

export default Profile;