import React, { useState, useEffect, useRef } from 'react';
import Home from './pages/Home';
import Plans from './pages/Plans';
import Profile from './pages/Profile';
import styled from 'styled-components';
import NavigationMenu from './components/NavigationMenu';
import AdminDashboard from './components/AdminDashboard';
import GlobalStyle from './GlobalStyle';
import './App.css';
import { requestNotificationsPermission } from './firebase';
import { LocationProvider } from './context/LocationContext';
import useCreateDefaultUser from './hooks/useCreateDefaultUser';
import LocationSimulator from "./context/LocationSimulator";

const AppContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  position: relative;
  overflow: auto;
  padding-bottom: ${({ activeIndex }) => (activeIndex === 3 ? '70px' : '0')};
`;

const App = () => {
    const [activeIndex, setActiveIndex] = useState(1);
    const homeRef = useRef(null);
    const { loading, userProfile } = useCreateDefaultUser();
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isStandalone, setIsStandalone] = useState(false);
    const [isIOS, setIsIOS] = useState(false);

    useEffect(() => {
        requestNotificationsPermission();

        if (window.matchMedia('(display-mode: standalone)').matches) {
            setIsStandalone(true);
        }

        const userAgent = window.navigator.userAgent.toLowerCase();
        if (/iphone|ipad|ipod/.test(userAgent)) {
            setIsIOS(true);
        }

        window.addEventListener('beforeinstallprompt', (e) => {
            if (!isIOS) {
                e.preventDefault();
                setDeferredPrompt(e);
            }
        });

        window.addEventListener('appinstalled', () => {
            setDeferredPrompt(null);
        });
    }, [isIOS]);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                setDeferredPrompt(null);
            });
        }
    };

    const handleAddCurrentLocationMarker = () => {
        if (homeRef.current) {
            homeRef.current.handleAddCurrentLocationMarker();
        }
    };

    const renderComponent = () => {
        switch (activeIndex) {
            case 0:
                return <Plans />;
            case 1:
                return <Home ref={homeRef} />;
            case 2:
                return <Profile />;
            case 3:
                if (userProfile?.role === 'admin') {
                    return <AdminDashboard />
                } else {
                    return <div>Access Denied</div>
                }
            default:
                return <Home ref={homeRef} />;
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!isStandalone) {
        return (
            <div className="install-prompt">
                <img src="/favicon.ico" alt="App Logo" className="app-logo" />
                {isIOS ? (
                    <div className="instructions">
                        <p>Pour continuer, installez cette application sur votre appareil :</p>
                        <p><strong>1. Appuyez sur l'icône de partage</strong> <ion-icon name="share-outline"></ion-icon></p>
                        <img src="/ios-instructions.png" alt="Instructions iOS" />
                        <p><strong>2. Puis sélectionnez Ajouter à l'écran d'accueil</strong></p>
                    </div>
                ) : (
                    <>
                        <p>Ajouter Les Méchants à votre écran d'accueil pour une expérience optimale.</p>
                        <button onClick={handleInstallClick}>Installer</button>
                    </>
                )}
            </div>
        );
    }

    return (
        <LocationProvider>
            {/*<LocationSimulator />*/}
            <AppContainer>
                <GlobalStyle />
                <Content activeIndex={activeIndex}>
                    {renderComponent()}
                </Content>
                <NavigationMenu
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    onAddCurrentLocationMarker={handleAddCurrentLocationMarker}
                />
            </AppContainer>
        </LocationProvider>
    );
};

export default App;