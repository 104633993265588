import React, { forwardRef, useState } from 'react';
import './styles/MarkerMenu.css';

const MarkerMenu = forwardRef(({ onSelect }, ref) => {
    const [selectedType, setSelectedType] = useState(null);
    const [comment, setComment] = useState('');

    const handleSelect = (type) => {
        setSelectedType(type);
    };

    const handleSubmit = () => {
        onSelect(selectedType, comment);
        setSelectedType(null); // Reset the selection
        setComment(''); // Clear the comment
    };

    return (
        <div className="marker-menu" ref={ref}>
            {!selectedType ? (
                <>
                    <button onClick={() => handleSelect('accident')} className="menu-button">
                        <ion-icon name="alert-circle-outline" className="menu-icon" />
                        Accident
                    </button>
                    <button onClick={() => handleSelect('radar')} className="menu-button">
                        <ion-icon name="speedometer-outline" className="menu-icon" />
                        Radar
                    </button>
                    <button onClick={() => handleSelect('police')} className="menu-button">
                        <ion-icon name="shield-outline" className="menu-icon" />
                        Police
                    </button>
                </>
            ) : (
                <>
                    <textarea
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Ajouter un commentaire (facultatif)..."
                        className="comment-input"
                    />
                    <button onClick={handleSubmit} className="menu-button">
                        Valider
                    </button>
                </>
            )}
        </div>
    );
});

export default MarkerMenu;