import React from 'react';
import './styles/Snapcode.css';

const Snapcode = ({ imageUrl, title, profileUrl }) => {
    return (
        <a href={profileUrl} target="_blank" rel="noopener noreferrer" className="snapcode-link">
            <div className="snapcode">
                <img src={imageUrl} alt={title} className="snapcode-image" />
                <p>{title}</p>
            </div>
        </a>
    );
};

export default Snapcode;