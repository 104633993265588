import React, { useEffect, useState } from 'react';
import Snapcode from '../components/Snapcode';
import ServiceList from '../components/ServiceList';
import '../components/styles/Plans.css';
import { getSnapcodes, getServices } from '../utils/api';

const Plans = () => {
    const [featuredSnapcodes, setFeaturedSnapcodes] = useState([]);
    const [services, setServices] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const snapcodesData = await getSnapcodes();
                setFeaturedSnapcodes(snapcodesData);

                let servicesData = await getServices();
                servicesData.push({ name: '', description: '', icon: '', profileUrl: '' });
                setServices(servicesData);
            } catch (error) {
                console.error('Erreur lors de la récupération des données:', error);
            }
        };

        fetchData();
    }, []);

    const handleContactClick = () => {
        alert("Contactez-nous à contact@lesmechants.com pour les collaborations et la publicité.");
    };

    return (
        <div className="plans">
            <div className="featured-snapcodes">
                {featuredSnapcodes.map((snapcode, index) => (
                    <Snapcode key={index} {...snapcode} />
                ))}
            </div>
            <div className="service-list-container">
                <ServiceList services={services} onContactClick={handleContactClick} />
            </div>
        </div>
    );
};

export default Plans;