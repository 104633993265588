import axios from 'axios';

const API_BASE_URL = 'https://lesmechants.slkzgm.com/api';

export const getMarkers = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/markers`);
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la récupération des marqueurs:', error);
        throw error;
    }
};

export const addMarker = async (marker) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/markers`, marker);
        return response.data;
    } catch (error) {
        console.error('Erreur lors de l\'ajout du marqueur:', error);
        throw error;
    }
};

export const addCommentToMarker = async (markerId, comment, deviceId) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/markers/comment`, { markerId, comment, deviceId });
        return response.data;
    } catch (error) {
        console.error('Erreur lors de l\'ajout du commentaire:', error);
        throw error;
    }
};

export const getMarkerById = async (id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/markers/${id}`);
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la récupération du marqueur:', error);
        throw error;
    }
};

export const saveToken = async (token) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/tokens/save-token`, { token });
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la sauvegarde du token:', error);
        throw error;
    }
}

export const saveUserProfile = async (formData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/users/profile`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la sauvegarde du profil utilisateur:', error);
        throw error;
    }
};

export const getSnapcodes = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/snapcodes`);
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la récupération des snapcodes:', error);
        throw error;
    }
};

export const updateSnapcode = async (id, snapcode) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/snapcodes/${id}`, snapcode);
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la mise à jour du snapcode:', error);
        throw error;
    }
};

export const getServices = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/services`);
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la récupération des services:', error);
        throw error;
    }
};

export const setAuthToken = token => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
};

export const loginOrRegister = async (deviceId, username, photoUrl) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/auth`, {
            deviceId,
            username,
            photoUrl
        });
        const { token, user } = response.data;
        localStorage.setItem('jwtToken', token);
        setAuthToken(token);
        return { token, user };
    } catch (error) {
        console.error('Erreur lors de l\'authentification:', error);
        throw error;
    }
};

export const validateToken = async (token) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/auth/validate-token`, {
            token
        });
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la validation du token:', error);
        throw error;
    }
};

export const createService = async (service) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/services`, service);
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la création du service:', error);
        throw error;
    }
};

export const updateService = async (id, service) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/services/${id}`, service);
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la mise à jour du service:', error);
        throw error;
    }
};

export const deleteService = async (id) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/services/${id}`);
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la suppression du service:', error);
        throw error;
    }
};

export const sendNotification = async (notification) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/notifications`, notification);
        return response.data;
    } catch (error) {
        console.error('Erreur lors de l\'envoi de la notification:', error);
        throw error;
    }
};

export const uploadImage = async (imageFile) => {
    try {
        const formData = new FormData();
        formData.append('image', imageFile);

        const response = await axios.post(`${API_BASE_URL}/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data.imageUrl;
    } catch (error) {
        console.error('Erreur lors de l\'upload de l\'image:', error);
        throw error;
    }
};