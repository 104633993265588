import React from 'react';
import Service from './Service';
import '../components/styles/ServiceList.css';

const ServiceList = ({ services, onContactClick }) => {
    return (
        <div className="service-list">
            {services.map((service, index) => (
                <Service key={index} {...service} onContactClick={onContactClick} />
            ))}
        </div>
    );
};

export default ServiceList;