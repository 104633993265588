import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { loginOrRegister, validateToken, setAuthToken, saveUserProfile } from '../utils/api';

const useCreateDefaultUser = () => {
    const [deviceId, setDeviceId] = useState('');
    const [userProfile, setUserProfile] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const initializeUser = async () => {
            let storedDeviceId = localStorage.getItem('deviceId');
            if (!storedDeviceId) {
                storedDeviceId = uuidv4();
                localStorage.setItem('deviceId', storedDeviceId);
            }
            setDeviceId(storedDeviceId);

            let storedToken = localStorage.getItem('jwtToken');
            if (storedToken) {
                setAuthToken(storedToken);
                const storedUser = JSON.parse(localStorage.getItem('userProfile'));
                if (storedUser) {
                    setUserProfile(storedUser);
                    setLoading(false);
                    return;
                }
            }

            try {
                const { token, user } = await loginOrRegister(storedDeviceId, storedDeviceId.slice(0, 8), 'pfp/default-profile.jpg');
                localStorage.setItem('jwtToken', token);
                localStorage.setItem('userProfile', JSON.stringify(user));
                setAuthToken(token);
                setUserProfile(user);
            } catch (error) {
                console.error('Erreur lors de l\'authentification:', error);
                setUserProfile({
                    username: storedDeviceId.slice(0, 8),
                    photoUrl: '/pfp/default-profile.jpg',
                });
            } finally {
                setLoading(false);
            }
        };

        initializeUser();
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                const token = localStorage.getItem('jwtToken');
                if (token) {
                    const response = await validateToken(token);
                    const { user, newToken } = response;

                    localStorage.setItem('userProfile', JSON.stringify(user));
                    setUserProfile(user);

                    if (newToken) {
                        localStorage.setItem('jwtToken', newToken);
                        setAuthToken(newToken);
                    }
                }
            } catch (error) {
                console.error('Erreur lors de la validation du token:', error);
            }
        }, 300000);

        return () => clearInterval(interval);
    }, []);

    const updateUserProfile = async (profile) => {
        try {
            const formData = new FormData();
            formData.append('username', profile.username);
            formData.append('deviceId', profile.deviceId);
            if (profile.photoFile) {
                formData.append('photo', profile.photoFile);
            }

            const updatedUser = await saveUserProfile(formData);
            localStorage.setItem('userProfile', JSON.stringify(updatedUser));
            setUserProfile(updatedUser);
        } catch (error) {
            console.error('Erreur lors de la mise à jour du profil utilisateur:', error);
        }
    };

    return { deviceId, userProfile, loading, updateUserProfile };
};

export default useCreateDefaultUser;