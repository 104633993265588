import React from 'react';
import './styles/UserCard.css';

const UserCard = ({ username, photoUrl, onPhotoClick, editUsername, setEditUsername, handleUsernameChange, handleUsernameSave, handleUsernameCancel }) => {
    return (
        <div className="user-card">
            <div className="user-card-header">
                <div className="photo-container">
                    <img src={photoUrl} alt="Profile" className="user-photo" onClick={onPhotoClick} />
                    <ion-icon name="create-outline" className="edit-icon photo-edit-icon" onClick={onPhotoClick} />
                </div>
                {editUsername ? (
                    <div className="username-edit">
                        <input
                            type="text"
                            value={username}
                            onChange={handleUsernameChange}
                            className="username-input"
                            autoFocus
                        />
                        <div className="username-buttons">
                            <button onClick={handleUsernameSave} className="save-button">Save</button>
                            <button onClick={handleUsernameCancel} className="cancel-button">Cancel</button>
                        </div>
                    </div>
                ) : (
                    <div className="username-container">
                        <h2 className="username" onClick={() => setEditUsername(true)}>{username}</h2>
                        <ion-icon name="create-outline" className="edit-icon username-edit-icon" onClick={() => setEditUsername(true)} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserCard;