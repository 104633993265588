import React from 'react';
import '../components/styles/Service.css';

const Service = ({ name, description, icon, profileUrl, backgroundImage, onContactClick }) => {
    const serviceStyle = {
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        color: backgroundImage ? '#fff' : '#000',
    };

    const overlayStyle = backgroundImage ? {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    } : {};

    const contentStyle = {
        position: 'relative',
        zIndex: 1,
    };

    if (name === "" && description === "" && icon === "" && profileUrl === "") {
        return (
            <div className="service-link" onClick={onContactClick}>
                <div className="service" style={serviceStyle}>
                    {backgroundImage && <div style={overlayStyle}></div>}
                    <div className="service-icon" style={contentStyle}>
                        <ion-icon name="add-circle-outline"></ion-icon>
                    </div>
                    <div className="service-details" style={contentStyle}>
                        <h3>Ajouter votre service</h3>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <a href={profileUrl} target="_blank" rel="noopener noreferrer" className="service-link">
            <div className="service" style={serviceStyle}>
                {backgroundImage && <div style={overlayStyle}></div>}
                <div className="service-icon" style={contentStyle}>
                    {icon && <ion-icon name={icon}></ion-icon>}
                </div>
                <div className="service-details" style={contentStyle}>
                    <h3>{name}</h3>
                    <p>{description}</p>
                </div>
            </div>
        </a>
    );
};

export default Service;
