import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useContext } from 'react';
import Map from '../components/Map';
import MarkerMenu from '../components/MarkerMenu';
import { getMarkers, addMarker } from '../utils/api';
import styled from 'styled-components';
import { LocationContext } from "../context/LocationContext";
import useCreateDefaultUser from '../hooks/useCreateDefaultUser';

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MapContainer = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
`;

const Home = forwardRef((props, ref) => {
    const { location } = useContext(LocationContext);
    const { deviceId } = useCreateDefaultUser();
    const [markers, setMarkers] = useState([]);
    const [menuVisible, setMenuVisible] = useState(false);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [mapCenter, setMapCenter] = useState([50.35768365946358, 3.525737477589137]);
    const [mapZoom, setMapZoom] = useState(13);
    const [shouldCenter, setShouldCenter] = useState(true);
    const menuRef = useRef(null);

    useEffect(() => {
        if (location && shouldCenter) {
            // Update map center and zoom when location is updated
            setMapCenter([location.latitude, location.longitude]);
            setMapZoom(17); // Set desired zoom level when location is found
            setShouldCenter(false); // Avoid centering after the initial one
        }
    }, [location, shouldCenter]);

    useEffect(() => {
        const fetchMarkers = async () => {
            try {
                const markersData = await getMarkers();
                setMarkers(markersData);
            } catch (error) {
                console.error('Erreur lors de la récupération des marqueurs:', error);
            }
        };
        fetchMarkers();
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuVisible(false);
                setSelectedPosition(null);
            }
        };

        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                setMenuVisible(false);
                setSelectedPosition(null);
            }
        };

        if (menuVisible) {
            document.addEventListener('mousedown', handleOutsideClick);
            document.addEventListener('keydown', handleEscapeKey);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('keydown', handleEscapeKey);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [menuVisible]);

    const handleMapClick = (e) => {
        setSelectedPosition({ lat: e.latlng.lat, lng: e.latlng.lng });
        setMenuVisible(true);
    };

    const handleAddMarker = async (type, comment) => {
        const newMarker = {
            ...selectedPosition,
            type,
            comment,
            deviceId,
        };
        try {
            const addedMarker = await addMarker(newMarker);
            setMarkers([...markers, addedMarker]);
            setMenuVisible(false);
            setSelectedPosition(null); // Reset to actual location after putting a marker
        } catch (error) {
            console.error('Erreur lors de l\'ajout du marqueur:', error);
        }
    };

    useImperativeHandle(ref, () => ({
        handleAddCurrentLocationMarker
    }));

    const handleAddCurrentLocationMarker = () => {
        if (location) {
            setSelectedPosition({ lat: location.latitude, lng: location.longitude });
            setMenuVisible(true);
        }
    };

    return (
        <HomeContainer>
            <MapContainer>
                <Map
                    location={location}
                    markers={markers}
                    onMapClick={handleMapClick}
                    currentPosition={selectedPosition}
                    mapCenter={mapCenter}
                    mapZoom={mapZoom}
                    shouldCenter={shouldCenter}
                />
                {menuVisible && (
                    <MarkerMenu
                        onSelect={handleAddMarker}
                        ref={menuRef}
                        position={selectedPosition}
                    />
                )}
            </MapContainer>
        </HomeContainer>
    );
});

export default Home;