import React, { createContext, useState, useEffect, useCallback } from 'react';

export const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
    const [location, setLocation] = useState(null);
    const [locationDenied, setLocationDenied] = useState(false);

    const updateLocation = useCallback((position) => {
        const coords = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
        };
        setLocation(coords);
        localStorage.setItem('location', JSON.stringify(coords));
        setLocationDenied(false);
    }, []);

    const requestLocation = useCallback(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                updateLocation,
                (error) => {
                    console.error('Error getting location:', error);
                    const storedLocation = JSON.parse(localStorage.getItem('location'));
                    if (storedLocation) {
                        setLocation(storedLocation);
                    } else {
                        setLocationDenied(true);
                    }
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
            setLocationDenied(true);
        }
    }, [updateLocation]);

    const watchLocation = useCallback(() => {
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(
                updateLocation,
                (error) => {
                    console.error('Error watching location:', error);
                    const storedLocation = JSON.parse(localStorage.getItem('location'));
                    if (storedLocation) {
                        setLocation(storedLocation);
                    } else {
                        setLocationDenied(true);
                    }
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
            setLocationDenied(true);
        }
    }, [updateLocation]);

    useEffect(() => {
        watchLocation(); // Start watching the location on component mount
    }, [watchLocation]);

    return (
        <LocationContext.Provider value={{ location, setLocation, locationDenied, setLocationDenied, requestLocation }}>
            {children}
        </LocationContext.Provider>
    );
};